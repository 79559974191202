:root {
    --main: #205081;
    --white: #ffffff;
    --aux-color: #3B7FC4;
}
#root {
    width: 50%;
    height: 50%;
}
body {
    height: 94vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: lightgray;
}
#Container {    
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 20px;
    background-color: #f6f6f6;
}
#AppContainer {
    overflow: hidden;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    height: 100%;
    position: relative;
}
#footer{
    position: fixed;
   left: 0;
   bottom: 0;
   width: 100%;
   background-color: transparent;
   color: inherit;   
   font-size: 14px;   
   font-weight: 200;
   text-align: end;
   padding: 0 10px;
}
#Toolbar {
    display: flex;
    align-items: center;
    background-color: var(--main);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    justify-content: center;
    width: 100%;
    flex-direction: row;
    gap: 20px;
    border-bottom: 1px solid rgba(255,255,255, 0.4);
    padding: 8px 5px;
    height: 50px;
}
#company-logo {        
    width: 120px;    
}
#Vert-Divider {
    width: 1px;
    background-color: rgba(255,255,255, 0.4);
    height: 100%;
}
#Contract-Name {
    padding-top: 2px;
    max-width: 60%;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    animation: fadeIn 2s;
}
#DocsEmdha {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding: 50px;
    gap: 50px;        
}

#PdfLogo {
    width: 50px;
    object-fit: contain;
    aspect-ratio: 3/2;
}

#Docs-Container {
    display: grid;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
}
#EmdhaLogo {   
    border-radius: 2px;
    width: 50px;
}
.Doc-Order {
    height: 40px;
    border-radius: 1px;
    color: white;
    padding: 0 10px;
}

.Doc-Name {
    font-size: 16px;
    text-align: center;
    overflow: clip;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-clamp: 1;
    max-width: 300px;
}
#Docs-Signed-Container {
    position: relative;
    top: 0;
    left: 0;
}

#PdfSign {
    position: relative;
    top: 0;
    left: 0;
    width: 150px;
    object-fit: contain;
    aspect-ratio: 3/2;
}

#EmdhaSign {
    position: absolute;
    top: 50px;
    left: 50px;
    width: 50px;
}

HTML {
    /*using system font-stack*/
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 115%; /*~18px*/
    font-size: calc(12px + (25 - 12) * (100vw - 300px) / (1300 - 300) );
    line-height: 1.5;
    box-sizing: border-box;
}



p {
    color: #000;
    font-weight: 500;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

*, *::before, *::after {
    box-sizing: inherit;
    color: inherit;
}

/*Actual Style*/

/*=======================
       C-Container
=========================*/
.c-container {
    max-width: 27rem;
    margin: 1rem auto 0;
    padding: 1rem;
}

/*=======================
       O-Circle
=========================*/

.o-circle {
    display: flex;
    width: 7.555rem;
    height: 7.555rem;
    justify-content: center;
    align-items: flex-start;
    border-radius: 50%;
    animation: circle-appearance .8s ease-in-out 1 forwards, set-overflow .1s 1.1s forwards;
}

/*=======================
    C-Circle Sign
=========================*/

.o-circle__sign {
    position: relative;
    opacity: 0;
    background: #fff;
    animation-duration: .8s;
    animation-delay: .2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

    .o-circle__sign::before,
    .o-circle__sign::after {
        content: "";
        position: absolute;
        background: inherit;
    }

    .o-circle__sign::after {
        left: 100%;
        top: 0%;
        width: 500%;
        height: 95%;
        transform: translateY(4%) rotate(0deg);
        border-radius: 0;
        opacity: 0;
        animation: set-shaddow 0s 1.13s ease-in-out forwards;
        z-index: -1;
    }


/*=======================
      Sign Success
=========================*/

.o-circle__sign--success {
    background: rgb(56, 176, 131);
}

    .o-circle__sign--success .o-circle__sign {
        width: 1rem;
        height: 5rem;
        border-radius: 50% 50% 50% 0% / 10%;
        transform: translateX(130%) translateY(35%) rotate(45deg) scale(.11);
        animation-name: success-sign-appearance;
    }

        .o-circle__sign--success .o-circle__sign::before {
            bottom: -17%;
            width: 100%;
            height: 50%;
            transform: translateX(-80%) rotate(90deg);
            border-radius: 50% 50% 50% 50% / 20%;
        }

        /*--shadow--*/
        .o-circle__sign--success .o-circle__sign::after {
            background: rgb(20, 102, 72);
        }


/*=======================
      Sign Failure
=========================*/

.o-circle__sign--failure {
    background: rgb(236, 78, 75);
}

    .o-circle__sign--failure .o-circle__sign {
        width: 1rem;
        height: 4rem;
        transform: translateY(25%) rotate(45deg) scale(.1);
        border-radius: 50% 50% 50% 50% / 10%;
        animation-name: failure-sign-appearance;
    }

        .o-circle__sign--failure .o-circle__sign::before {
            top: 50%;
            width: 100%;
            height: 100%;
            transform: translateY(-50%) rotate(90deg);
            border-radius: inherit;
        }

        /*--shadow--*/
        .o-circle__sign--failure .o-circle__sign::after {
            background: rgba(175, 57, 55, .8);
        }


/*-----------------------
      @Keyframes
--------------------------*/

/*CIRCLE*/
@keyframes circle-appearance {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1.5);
    }

    60% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
    }
}

/*SIGN*/
@keyframes failure-sign-appearance {
    50% {
        opacity: 1;
        transform: translateY(25%) rotate(45deg) scale(1.7);
    }

    100% {
        opacity: 1;
        transform: translateY(25%) rotate(45deg) scale(1);
    }
}

@keyframes success-sign-appearance {
    50% {
        opacity: 1;
        transform: translateX(130%) translateY(35%) rotate(45deg) scale(1.7);
    }

    100% {
        opacity: 1;
        transform: translateX(130%) translateY(35%) rotate(45deg) scale(1);
    }
}


@keyframes set-shaddow {
    to {
        opacity: 1;
    }
}

@keyframes set-overflow {
    to {
        overflow: hidden;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


/*+++++++++++++++++++
    @Media Queries
+++++++++++++++++++++*/

@media screen and (min-width: 1300px) {

    HTML {
        font-size: 1.5625em;
    }
    /* 25 / 16 = 1.5625 */

}
